export const menuItems = [
    //DERNIER ID EST 34
    {
        id: 1,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        badge: {
            variant: "success",
            text: "1"
        },
        link: '/',
    },
    {
        id: 2,
        label: 'Profil',
        icon: 'fas fa-ca',
        link: '/profile',
    },
    {
        id: 2,
        label: 'menuitems.menu_vehicule.text',
        isTitle: true,
        //groupe: 11, 
    },
    {
        id: 3,
        label: 'menuitems.ajout_vehicule.text',
        icon: 'fas fa-ca',
        link: '/vehicule/add_car',
        //groupe: 12, 
    },
    {
        id: 4,
        label: 'menuitems.liste_vehicule.text',
        icon: 'fad fa-ca',
        link: '/vehicule/list_car',
        //groupe: 12, 
    },
    {
        id: 5,
        label: 'menuitems.vidange.text',
        icon: 'fas fa-ca',
        link: '/vidange/vidange',
        //groupe: 15, 
    },
    /*{
        id: 6,
        label: 'menuitems.reparation.text',
        icon: 'fas fa-',
        link: '/suivi/reparation',
        //groupe: 13, 
    },*/
    {
        id: 7,
        label: 'menuitems.menu_chauffeur.text',
        isTitle: true,
        //groupe: 11, 
    },
    {
        id: 8,
        label: 'menuitems.ajout_chauffeur.text',
        icon: 'fas fa-driver',
        link: '/chauffeur/add',
        //groupe: 12, 
    },
    {
        id: 9,
        label: 'menuitems.liste_chauffeur.text',
        icon: 'fas fa-',
        link: '/chauffeur/list',
        //groupe: 12, 
    },
    {
        id: 10,
        label: 'menuitems.menu_client.text',
        isTitle: true,
        //groupe: 11, 
    },
    {
        id: 11,
        label: 'menuitems.ajout_client.text',
        icon: 'fas fa-driver',
        link: '/client/add',
        //groupe: 12, 
    },
    {
        id: 12,
        label: 'menuitems.liste_client.text',
        icon: 'fas fa-',
        link: '/client/list',
        //groupe: 12, 
    },
    {
        id: 13,
        label: 'menuitems.location_auto.text',
        isTitle: true,
        //groupe: 11, 
    },
    {
        id: 14,
        label: 'menuitems.retour_auto.text',
        icon: 'fas fa-location',
        link: '/historique/retourner_rmo',
        //groupe: 12, 
    },
    {
        id: 15,
        label: 'menuitems.historique_auto.text',
        icon: 'fas fa-ca',
        link: '/historique/automatique',
        //groupe: 12, 
    },
    {
        id: 16,
        label: 'menuitems.location_manuelle.text',
        isTitle: true,
        //groupe: 11, 
    },
    {
        id: 17,
        label: 'menuitems.louer_vehicule_manuelle.text',
        icon: 'fas fa-car-location',
        link: '/location/manuelle',
        //groupe: 12, 
    },
    {
        id: 18,
        label: 'menuitems.historique_manuelle.text',
        icon: 'fas fa-ca',
        link: '/historique/manuelle',
        //groupe: 13, 
    },
    {
        id: 19,
        label: 'menuitems.menu_synthese.text',
        isTitle: true,
        //groupe: 11, 
    },
    {
        id: 20,
        label: 'menuitems.synthese.text',
        icon: 'fas fa-ca',
        link: '/synthese/manuelle',
        //groupe: 12, 
    },
    {
        id: 21,
        label: 'menuitems.synthese_rm.text',
        icon: 'fas fa-ca',
        link: '/synthese/synthese',
        //groupe: 12, 
    },
    /*{
        id: 22,
        label: 'menuitems.ajout_agence.text',
        isTitle: true,
        //groupe: 11, 
    },
    {
        id: 23,
        label: 'menuitems.avis_reçu.text',
        icon: 'fas fa-ca',
        link: '/avis/avis',
        groupe: 13, 
    },*/
  /*   {
        id: 67,
        label: 'menuitems.menu_abonnement.text',
        isTitle: true,
        groupe: 11, 
    },
    {
        id: 68,
        label: 'menuitems.abonnement.text',
        icon: 'fas fa-ca',
        link: '/abonnement',
        groupe: 12, 
    }, */
    /* {
        id: 63,
        label: 'menuitems.menu_motdepasse.text',
        isTitle: true,
        groupe: 11, 
    },*/
]


